import React from "react";
import { Link } from "react-router-dom";

const DownloadBrochure = () => {
  return (
    <section className="download_brochure">
      <div className="Custom_container">
        <div className="wrap_brochure">
          <h2 className="h2_subheading">Download Brochure for More Details</h2>
          <p className="span_cnt">
            Welcome to NLIM, a pioneering institute that redefines hospitality
            education for the modern world. With a steadfast commitment to.
          </p>
          {/* <Link className="nav_btn_cstm_more" to="#">
            Download Now
          </Link> */}
          <a
            className="nav_btn_cstm_more"
            href="/prospectus.pdf"
            download="prospectus.pdf"
          >
            Download Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default DownloadBrochure;
