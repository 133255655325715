// CommonModal.js
import React, { useRef, useState } from "react";
import "./modal.scss";
import { Form, Modal } from "react-bootstrap";

import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
const CommonModal = ({ show, handleClose, customClass }) => {
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    " Submit Message",
    "Please wait..."
  );

  const [formData, setFormData] = useState({
    firstName: "",
    mobileNumber: "",
    email: "",
    course: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    mobileNumber: "",
    email: "",
    course: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null,
    });
  };
  const form = useRef();
  const errHandler = () => {
    // Validate form fields
    let formIsValidate = true;
    const validationErrors = {};
    if (!formData.firstName.trim()) {
      validationErrors.firstName = "First Name is required";
      formIsValidate = false;
    }
    if (!formData.mobileNumber.trim()) {
      validationErrors.mobileNumber = "Mobile Number is required";
      formIsValidate = false;
    }
    // else if (
    //   isNaN(formData.mobileNumber) ||
    //   formData.mobileNumber.length !== 10
    // ) {
    //   validationErrors.mobileNumber =
    //     "Mobile Number should be a valid 10-digit number";
    //   formIsValidate = false;
    // }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is invalid";
      formIsValidate = false;
    }
    if (!formData.course.trim()) {
      validationErrors.course = "Course selection is required";
      formIsValidate = false;
    }

    setErrors(validationErrors);
    return formIsValidate;
  };
  const finalSubmitHandler = () => {
    if (errHandler()) {
      const templateParams = {
        applicant_mobile_number: formData.mobileNumber,
        applicant_mail: formData.email,
        applicant_name: formData.firstName,
        course_name: formData.course,
      };
      console.log(templateParams, "emailParams");
      setSubmitLoading(true);
      emailjs
        .send("service_le5ewcd", "template_h30dwtq", templateParams, {
          publicKey: "Hqybjk1JKXCYcNn29",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            toast.success("Application submitted successfully!");
            setSubmitLoading(false);
            setFormData({
              firstName: "",
              mobileNumber: "",
              email: "",
              course: "",
            });
            handleClose(true);
          },
          (error) => {
            console.log("FAILED...", error);
            toast.error("Failed to submit application. Please try again");
          }
        );
    } else {
      errHandler();
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={customClass}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="h3_subheading">Apply Now</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="wrap_form_field">
            <div className="form_field">
              <label className="label">Your Full Name</label>
              <input
                type="text"
                name={"firstName"}
                className="form-control input"
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <div className="error" style={{ color: "red" }}>
                  {errors.firstName}
                </div>
              )}
            </div>
            <div className="form_field">
              <label className="label">Mobile Number</label>
              <input
                type="number"
                name={"mobileNumber"}
                className="form-control input"
                placeholder="Enter your mobile"
                value={formData.mobileNumber}
                onChange={handleInputChange}
                // maxLength={10}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
              />
              {errors.mobileNumber && (
                <div className="error" style={{ color: "red" }}>
                  {errors.mobileNumber}{" "}
                </div>
              )}
            </div>
          </div>
          <div className="wrap_form_field">
            <div className="form_field">
              <label className="label">Email address</label>
              <input
                type="email"
                name="email"
                className="form-control input"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className="error" style={{ color: "red" }}>
                  {errors.email}
                </div>
              )}
            </div>
            <div className="form_field">
              <label className="label">Course</label>
              <Form.Select
                aria-label="Default select example"
                name="course"
                className="form-control input"
                value={formData.course}
                onChange={handleInputChange}
              >
                <option>Select Course</option>
                <option value="Diploma in Hotel Management">
                  Diploma in Hotel Management
                </option>
                <option value="Advanced Diploma in Hotel Management">
                  Advanced Diploma in Hotel Management
                </option>
                <option value="Bachelor in Vocational Studies in Hotel Management">
                  Bachelor in Vocational Studies in Hotel Management
                </option>
              </Form.Select>
              {/* {errors.course && <div className="error danger text">{errors.course}</div>} */}

              {errors.course && (
                <div className="error" style={{ color: "red" }}>
                  {errors.course}
                </div>
              )}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="form_field text_right">
          <button
            ref={submitButtonElement}
            className="nav_btn_cstm_more"
            onClick={() => {
              finalSubmitHandler();
            }}
          >
            Submit Message
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default CommonModal;
