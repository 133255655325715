import React from "react";
import { about, diploma3 } from "../DefaultImport";
import { Link } from "react-router-dom";
import CommonModal from "../components/modal/Modal";
import { DownloadBrochure } from "../DefaultImportComponent";

const BachelorStudies = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="Bachelor_studies_page">
      <section className="wrapper_about_heading">
        <div className="Custom_container">
          <div className="wrap_tittle">
            <h1 className="tittle_heading_h1">
              Bachelor in Vocational Studies
            </h1>
            <p className="tittle_sub_heading_p">3-year degree course</p>
          </div>
        </div>
      </section>
      <section className="wrapper_about_section">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="txt_content">
                  <h2 className="h2_subheading">
                    Bachelor in Vocational Studies in Hotel Management
                  </h2>
                  <p className="span_cnt">
                    Our Bachelor in Vocational Studies in Hotel Management is a
                    comprehensive 3-year degree program that combines academic
                    excellence with practical training. In addition to core
                    courses in hotel management, you will have the opportunity
                    to specialize in areas such as event management, hospitality
                    marketing, and sustainable tourism.
                  </p>
                  <p className="span_cnt">
                    <b>
                      Through a series of internships and industry projects,
                    </b> you will gain valuable hands-on experience and develop a deep
                  understanding of the hospitality industry. Upon graduation,
                  you will be ready to take on leadership roles in hotels,
                  resorts, restaurants, and other hospitality establishments, or
                  pursue further studies at the master's degree level.
                  </p>
                  
                  <button
                    onClick={() => setModalShow(true)}
                    className="nav_btn_cstm_more"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={diploma3} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DownloadBrochure />
      <CommonModal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        handleClose={() => setModalShow(false)}
        centered
      ></CommonModal>
    </div>
  );
};

export default BachelorStudies;
