import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import {
  footerimg,
  vector,
  github,
  facebook,
  instagram,
  logo_new,
  map,
  phone,
  letter,
  shapearrow,
} from "../DefaultImport";
import CommonModal from "./modal/Modal";

const Footer = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <footer className="footer">
        {/* <figure className="arrow_set">
          <img src={shapearrow} alt="" />
        </figure>
        <div className="wrap_footer">
          <div className="Custom_container">
            <div className="row cstm_row">
              <div className="col-lg-8 col-md-8 col-sm-6 col-6">
                <div className="headings">
                  <h2 className="h2_subheading">
                    Ready to grow your <span className="break_txt"></span>{" "}
                    business digitally?
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                <div className="footer_btn">
                  <button
                    className="nav_btn_cstm"
                    onClick={() => setModalShow(true)}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="wrap_social_footer">
          <div className="Custom_container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                <div className="wrap_footer_logo">
                  <figure>
                    <img src={logo_new} alt="" />
                  </figure>
                  <span className="sub_span_footer">
                    Welcome to NLIM, a pioneering institute that redefines
                    hospitality education for the modern world.
                  </span>
                  <Link className="sub_span_footer" to="mailto:info@nlim.in">
                    info@nlim.in
                  </Link>
                  <ul>
                    {/* <li>
                      <Link
                        className="cstm_anchor"
                        to="https://twitter.com/?lang=en"
                        target="blank"
                      >
                        <img src={vector} alt="" />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        className="cstm_anchor"
                        to="https://www.facebook.com/"
                        target="blank"
                      >
                        <img src={facebook} alt="" />
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        className="cstm_anchor"
                        to="https://www.instagram.com/"
                        target="blank"
                      >
                        <img src={instagram} alt="" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="cstm_anchor"
                        to="https://github.com/"
                        target="blank"
                      >
                        <img src={github} alt="" />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                <div className="wrapper_quick_links">
                  <p className="sub_p_footer">Quick Links</p>
                  <ul>
                    <li>
                      <Link className="sub_span_footer" to="/about">
                        About us
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="sub_span_footer" to="javascript:void(0)">
                        Institution
                      </Link>
                    </li>
                    <li>
                      <Link className="sub_span_footer" to="/admission">
                        Admission
                      </Link>
                    </li>
                    <li>
                      <Link className="sub_span_footer" to="javascript:void(0)">
                        Faculty
                      </Link>
                    </li> */}
                    <li>
                      <Link className="sub_span_footer" to="/gallery">
                        Gallery
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="wrapper_quick_links">
                  <p className="sub_p_footer">Courses</p>
                  <ul>
                    <li>
                      <Link className="sub_span_footer" to="/diploma">
                        Diploma in Hotel Management
                      </Link>
                    </li>
                    <li>
                      <Link className="sub_span_footer" to="/advancediploma">
                        Advanced diploma in hotel management
                      </Link>
                    </li>
                    <li>
                      <Link className="sub_span_footer" to="/bachelorstudies">
                        Bachelor in vocational studies in hotel management
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="wrapper_quick_links">
                  <p className="sub_p_footer">Contact Info</p>
                  <ul>
                    <li>
                      <Link className="sub_span_footer" to="tel:+91 9876543210">
                        <img src={phone} alt="" />
                        +(91) 9876543210
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="sub_span_footer"
                        to="mailto:info@nlim.in"
                      >
                        <img src={letter} alt="" />
                        info@nlim.in
                      </Link>
                    </li>
                    <li>
                      <Link className="sub_span_footer" to="javascript:void(0)">
                        <img src={map} alt="" />
                        <span className="sub_span_footer">
                          Plot no. 10, Knowledge Park II, Greater Noida, Uttar
                          Pradesh 201310
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_footer">
          <span className="sub_span_footer">
            © Copyright 2024, All Rights Reserved by Ripenapps
          </span>
        </div>
      </footer>
      <CommonModal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        handleClose={() => setModalShow(false)}
        centered
      ></CommonModal>
    </>
  );
};

export default Footer;
