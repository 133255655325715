import React, { useState } from "react";
import { Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { IoIosArrowDown } from "react-icons/io";
import "./header.scss";
import {
  facebook,
  instagram,
  letter,
  // logo,
  logo_new,
  map,
  phone,
  pinterest,
  reddit,
  vector,
  youtube,
} from "../DefaultImport";
import CommonModal from "./modal/Modal";

const Header = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <header>
        <div className="sub_header">
          <div className="sub_contact">
            <Link to="tel:+91 9876543210">
              <img src={phone} alt=" logo" />
              <span>+(91) 9876543210</span>
            </Link>
            <Link to="mailto:info@nlim.in">
              <img src={letter} alt=" logo" />
              <span>info@nlim.in</span>
            </Link>
            <Link to="javascript:void(0)">
              <img src={map} alt=" logo" />
              <span>
                Plot no. 10, Knowledge Park II, Greater Noida, Uttar Pradesh
                201310
              </span>
            </Link>
          </div>
          <div className="socila_links">
            <span>Follow us:</span>
            {/* <Link to="https://twitter.com/?lang=en" target="blank">
              <img src={vector} alt=" logo" />
            </Link>
            <Link to="https://www.facebook.com/" target="blank">
              <img src={facebook} alt=" logo" />
            </Link>
            <Link to="https://in.pinterest.com/" target="blank">
              <img src={pinterest} alt=" logo" />
            </Link>
            <Link to="https://www.reddit.com/?rdt=33347" target="blank">
              <img src={reddit} alt=" logo" />
            </Link>
            <Link to="https://www.youtube.com/" target="blank">
              <img src={youtube} alt=" logo" />
            </Link> */}
            <Link to="https://www.instagram.com/nlim_noida" target="blank">
              <img src={instagram} alt=" logo" />
            </Link>
          </div>
        </div>
        <div className="wrapper_header">
          <div className="Custom_container">
            {["lg"].map((expand) => (
              <Navbar
                key={expand}
                expand={expand}
                className="bg-body-tertiary mb-2 mt-2"
              >
                <Navbar.Brand>
                  <Link className="logo_icon" to="/">
                    <img src={logo_new} alt=" logo" />
                  </Link>
                  <div className="desktop_header">
                    <Nav
                      className="justify-content-end flex-grow-1 pe-3"
                      onClick={() => setShow(false)}
                    >
                      <NavLink className="nav-link" to="/">
                        Home
                      </NavLink>
                      <NavLink className="nav-link" to="/about">
                        About Us
                      </NavLink>
                      <NavDropdown title="Courses" id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => navigate("/diploma")}>
                          Diploma in Hotel Management
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => navigate("/advancediploma")}
                        >
                          Advanced diploma in hotel management
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => navigate("/bachelorstudies")}
                        >
                          Bachelor in vocational studies in hotel management
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item href="#">
                        Separated link
                      </NavDropdown.Item> */}
                      </NavDropdown>
                      {/* <NavLink className="nav-link" to="/admission">
                        Admission
                      </NavLink> */}
                      <NavLink className="nav-link" to="/ourpartner">
                        Our Partner
                      </NavLink>
                      <NavLink className="nav-link" to="/gallery">
                        Gallery
                      </NavLink>
                      <NavLink className="nav-link" to="/contactus">
                        Contact Us
                      </NavLink>
                    </Nav>
                    <div className="wrapper_nav_btn">
                      <button
                        className="nav_btn_cstm"
                        onClick={() => setModalShow(true)}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </Navbar.Brand>

                <Navbar.Toggle show={show} onClick={handleShow} />

                <Offcanvas show={show} onHide={() => setShow(false)}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <img src={logo_new} alt=" logo" />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <NavLink
                        className="nav-link"
                        to="/"
                        onClick={() => setShow(false)}
                      >
                        Home
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        to="/about"
                        onClick={() => setShow(false)}
                      >
                        About Us
                      </NavLink>
                      <NavDropdown title="Courses" id="basic-nav-dropdown">
                        <NavDropdown.Item
                          onClick={() => {
                            navigate("/diploma");
                            setShow(false);
                          }}
                        >
                          Diploma in Hotel Management
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => {
                            navigate("/advancediploma");
                            setShow(false);
                          }}
                        >
                          Advanced diploma in hotel management
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => {
                            navigate("/bachelorstudies");
                            setShow(false);
                          }}
                        >
                          Bachelor in vocational studies in hotel management
                        </NavDropdown.Item>
                      </NavDropdown>
                      {/* <NavLink
                        className="nav-link"
                        to="/admission"
                        onClick={() => setShow(false)}
                      >
                        Admission
                      </NavLink> */}
                      <NavLink
                        className="nav-link"
                        to="/ourpartner"
                        onClick={() => setShow(false)}
                      >
                        Our Partner
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        to="/gallery"
                        onClick={() => setShow(false)}
                      >
                        Gallery
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        to="/contactus"
                        onClick={() => setShow(false)}
                      >
                        Contact Us
                      </NavLink>
                    </Nav>
                    <div
                      className="wrapper_nav_btn"
                      onClick={() => setShow(false)}
                    >
                      <button
                        className="nav_btn_cstm"
                        onClick={() => setModalShow(true)}
                      >
                        Apply Now
                      </button>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </Navbar>
            ))}
          </div>
        </div>
      </header>
      <CommonModal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        handleClose={() => setModalShow(false)}
        centered
      ></CommonModal>
    </>
  );
};

export default Header;
