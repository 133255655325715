import React from "react";
import { Link } from "react-router-dom";
import { about, partners, partners2, partners3 } from "../DefaultImport";

const OurPartner = () => {
  return (
    <div className="Our_partner_page">
      <section className="wrapper_about_heading">
        <div className="Custom_container">
          <div className="wrap_tittle">
            <h1 className="tittle_heading_h1">Our Partner</h1>
            <p className="tittle_sub_heading_p">
              We have extensive partnership and collaboration with the
              industry's leading organizations and businesses.
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper_about_section">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="txt_content">
                  <h2 className="h2_subheading">Our Partner</h2>

                  <p className="span_cnt">
                    We take pride in our strong partnerships and affiliations
                    with leading institutions and industry organizations. These
                    collaborations enhance our educational offerings and provide
                    our students with valuable opportunities for growth and
                    development.
                  </p>
                  <p className="span_cnt">
                    <b>
                      Our academic partners include renowned universities and
                      colleges,
                    </b>{" "}
                    allowing us to offer globally recognized programs to our
                    students. Additionally, our partnerships with industry
                    leaders enable us to develop a curriculum that is aligned
                    with current industry trends and demands, ensuring that our
                    students are well-prepared for successful careers in the
                    hospitality industry.
                  </p>
                  <p className="span_cnt">
                    <b>
                      Furthermore, our affiliations with professional
                      organizations and associations provide our students with
                      access to a vast network of industry professionals,
                    </b>{" "}
                    opening doors to internships, job placements, and mentorship
                    opportunities. Through these partnerships and affiliations,
                    we strive to provide our students with a well-rounded
                    education that combines academic excellence with practical
                    experience, setting them up for success in their future
                    careers.
                  </p>

                  <Link to="/contactus" className="nav_btn_cstm_more">
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={about} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="partners_gallery">
        <div className="Custom_container">
          <div className="wrap_gallery">
            <div className="wrap_partners_gallerys">
              <figure>
                <img src={partners} alt="" />
              </figure>
            </div>
            <div className="wrap_partners_gallerys">
              <figure>
                <img src={partners2} alt="" />
              </figure>
            </div>
            <div className="wrap_partners_gallerys">
              <figure>
                <img src={partners3} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurPartner;
