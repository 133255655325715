import React, { useState } from "react";
import { CiCalendar } from "react-icons/ci";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
// import { FiCalendar } from "react-icons/fi";
// import { FaArrowRight, FaRegUserCircle } from "react-icons/fa";
// import { GoArrowRight, GoArrowLeft } from "react-icons/go";

import {
  about,
  welcome,
  programe,
  leader,
  leaders,
  gallery,
  gallery2,
  gallery3,
  // update,
  // enterprenure,
  // business,
  ferns,
  leaders3,
  programe1,
  programe2,
  whychoose,
  // varrow,
} from "../DefaultImport";
import { Link } from "react-router-dom";
// import Slider from "react-slick";
import Marquee from "react-fast-marquee";
// import { Button, Form } from "react-bootstrap";
import CommonModal from "../components/modal/Modal";
import useButtonLoader from "../hooks/useButtonLoader";

const Landing = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Send Message",
    "Please wait..."
  );

  // const NextArrow = (props) => {
  //   const { className, onClick } = props;

  //   return (
  //     <span className={className} onClick={onClick}>
  //       <GoArrowRight />
  //     </span>
  //   );
  // };

  // const PrevArrow = (props) => {
  //   const { className, onClick } = props;

  //   return (
  //     <span className={className} onClick={onClick}>
  //       <GoArrowLeft />
  //     </span>
  //   );
  // };

  const arrays = [1, 2];
  // const settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null,
    });
  };
  const errHandler = () => {
    // Validate form fields
    let formIsValidate = true;
    const validationErrors = {};
    if (!formData.firstName.trim()) {
      validationErrors.firstName = "First Name is required";
      formIsValidate = false;
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = "Last Name is required";
      formIsValidate = false;
    }
    if (!formData.mobileNumber.trim()) {
      validationErrors.mobileNumber = "Mobile Number is required";
      formIsValidate = false;
    } else if (
      isNaN(formData.mobileNumber) ||
      formData.mobileNumber.length !== 10
    ) {
      validationErrors.mobileNumber =
        "Mobile Number should be a valid 10-digit number";
      formIsValidate = false;
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
      formIsValidate = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is invalid";
      formIsValidate = false;
    }
    if (!formData.subject.trim()) {
      validationErrors.subject = "Subject is required";
      formIsValidate = false;
    }
    if (!formData.message.trim()) {
      validationErrors.message = "Message is required";
      formIsValidate = false;
    }
    setErrors(validationErrors);
    return formIsValidate;
  };
  const finalSubmitHandler = (e) => {
    e.preventDefault();
    console.log(errHandler(), "handler:'");
    if (errHandler()) {
      console.log("emailParams++++");
      const templateParams = {
        mobile_number: formData.mobileNumber,
        email_address: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        subject: formData.subject,
        message: formData.message,
      };
      console.log(templateParams, "emailParams");
      setSubmitLoading(true);
      emailjs
        .send("service_le5ewcd", "template_n6kietg", templateParams, {
          publicKey: "Hqybjk1JKXCYcNn29",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            toast.success("Your message has been sent successfully!");
            setSubmitLoading(false);
            setFormData({
              firstName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
              subject: "",
              message: "",
            });
          },
          (error) => {
            console.log("FAILED...", error);
            toast.error("Failed to send your message. Please try again.");
          }
        );
    } else {
      errHandler();
    }
  };

  const description = `Kickstart your career in hospitality with our
  comprehensive 1-year diploma program, blend of classroom
  lectures, practical workshops, and industry visits`;

  const description1 = `Elevate your skills & knowledge with our 2-year program,
  designed to prepare you for leadership roles in the
  hospitality industry.`;
  const description2 = ` Dive deep into the world of hospitality with our 3-year
  degree program, offering a blend of academic excellence
  and practical training.`;

  return (
    <>
      <section className="wrapper_welcome">
        <div className="wrap_img_section">
          <figure>
            <img src={welcome} alt="" />
          </figure>
          <div className="Custom_container">
            <div className="heading_set">
              <h1>Welcome to NLIM</h1>
              <p>
                A pioneering institute that redefines hospitality <br />{" "}
                education for the modern world.
              </p>
              {/* <button className="nav_btn_cstm_more">Download Brochure</button> */}
              <a
                className="nav_btn_cstm_more"
                href="/prospectus.pdf"
                download="prospectus.pdf"
              >
                Download Brochure
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_about_section">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="txt_content">
                  <h2 className="h2_subheading">About Us</h2>
                  <p className="span_cnt">
                    Welcome to Northern Light Institute of Management, where we
                    redefine hospitality education. With a focus on practical
                    learning, industry relevance, and global exposure, we
                    prepare students for success in the dynamic world of
                    hospitality.
                  </p>
                  <p className="span_cnt">
                    <b>Experienced faculty, state-of-the-art facilities,</b>
                    and strong industry connections ensure that students receive
                    a comprehensive and holistic education. At Northern Light
                    Institute of Management, we are committed to nurturing the
                    next generation of hospitality leaders, equipping them with
                    the skills, knowledge, and confidence to excel in their
                    careers.
                  </p>
                  <p className="span_cnt">
                    Join us and embark on a transformative educational journey
                    that will open doors to exciting opportunities in the
                    hospitality industry.
                  </p>
                  <Link to="/about" className="nav_btn_cstm_more">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={about} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_programe">
        <div className="Custom_container">
          <div className="programe_content">
            <div className="headings">
              <h2 className="h2_subheading">Programs</h2>
              <p className="span_cnt">
                We offer future-ready courses and specialized programs that let
                you build a successful career in the hotel management industry.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="wrap_card">
                  <Link to="/diploma">
                    <figure>
                      <img src={programe} alt="" />
                    </figure>
                  </Link>
                  <div className="inner_card_wrap">
                    <p>Diploma in Hotel Management</p>
                    <span className="calendar_cstm">
                      <CiCalendar />
                      1-year diploma course
                    </span>
                    <span className="sub_span">
                      {description.length > 120
                        ? description.slice(0, 120) + "..."
                        : description}
                    </span>
                    <button
                      className="nav_btn_cstm_more w-100"
                      onClick={() => setModalShow(true)}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="wrap_card">
                  <Link to="/advancediploma">
                    <figure>
                      <img src={programe1} alt="" />
                    </figure>
                  </Link>
                  <div className="inner_card_wrap">
                    <p>Advanced Diploma in Hotel Manage...</p>
                    <span className="calendar_cstm">
                      <CiCalendar />
                      2-year diploma course
                    </span>
                    <span className="sub_span">
                      {description1.length > 120
                        ? description1.slice(0, 120) + "..."
                        : description1}
                    </span>
                    <button
                      className="nav_btn_cstm_more w-100"
                      onClick={() => setModalShow(true)}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="wrap_card">
                  <Link to="/bachelorstudies">
                    <figure>
                      <img src={programe2} alt="" />
                    </figure>
                  </Link>
                  <div className="inner_card_wrap">
                    <p>Bachelor in Vocational Studies in Hot...</p>
                    <span className="calendar_cstm">
                      <CiCalendar />
                      3-year diploma course
                    </span>
                    <span className="sub_span">
                      {description2.length > 120
                        ? description2.slice(0, 120) + "..."
                        : description2}
                    </span>
                    <button
                      className="nav_btn_cstm_more w-100"
                      onClick={() => setModalShow(true)}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_why_us">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={whychoose} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="txt_content">
                  <h2 className="h2_subheading">Why Choose Us</h2>
                  <p className="span_cnt">
                    At Northern Light Institute of Management, we offer a
                    transformative educational experience that sets us apart as
                    a beacon of excellence in hospitality education. Our
                    curriculum is designed to emphasize practical learning,
                    ensuring that students are not only equipped with
                    theoretical knowledge but also gain hands-on experience that
                    makes them industry-ready.
                  </p>
                  <p className="span_cnt">
                    <b>Developed in collaboration with industry experts,</b>
                    our courses are up-to-date and aligned with the latest
                    industry requirements, giving our students a competitive
                    edge. With our strong international connections, students
                    have access to global internships and placements, providing
                    them with invaluable international exposure. Our
                    state-of-the-art facilities create a conducive learning
                    environment, while our experienced faculty members, who are
                    themselves industry experts, provide students with valuable
                    insights and mentorship.
                  </p>
                  <p className="span_cnt">
                    Additionally, our comprehensive placement assistance program
                    helps students secure internships and jobs, further
                    enhancing their career prospects. Choose Northern Light
                    Institute of Management for a holistic educational
                    experience that prepares you for a successful career in the
                    hospitality industry.
                  </p>
                  <Link to="/about" className="nav_btn_cstm_more">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_leadership">
        <div className="Custom_container">
          <div className="programe_content">
            <div className="headings">
              <h2 className="h2_subheading">Our Leadership</h2>
              <p className="span_cnt">
                Be assured of the right guidance and expert leadership as our
                mentors come with years of industry experience and brilliant
                knowledge.
              </p>
            </div>
            <div className="wrap_leader_image">
              <div className="wrap_card">
                <figure>
                  <img src={leader} alt="" />
                  <div className="image_layer">
                    <p>Dr. SANGITA SHARMA</p>
                    <span>MD and CEO</span>
                  </div>
                </figure>
              </div>
              <div className="wrap_card">
                <figure>
                  <img src={leaders} alt="" />
                  <div className="image_layer">
                    <p>Amitabh Sahay</p>
                    <span>Director Training and Placement</span>
                  </div>
                </figure>
              </div>
              <div className="wrap_card">
                <figure>
                  <img src={leaders3} alt="" />
                  <div className="image_layer">
                    <p>SHAURYA SHARMA</p>
                    <span>Director PR and Legal </span>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_images_show">
        <div className="images_wrapper">
          <div className="images_custom_gallery">
            <figure>
              <img src={gallery} alt="gallery" />
            </figure>
            <figure>
              <img src={gallery2} alt="gallery" />
            </figure>
            <figure>
              <img src={gallery3} alt="gallery" />
            </figure>
          </div>
        </div>
      </section>
      {/* <section className="wrapper_latest_update">
        <div className="Custom_container">
          <div className="programe_content">
            <div className="headings">
              <h2 className="h2_subheading">Latest Update</h2>
              <p className="span_cnt">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text.
              </p>
            </div>
            <div className="row">
              <Slider {...settings}>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="wrap_card">
                    <figure>
                      <img src={update} alt="" />
                      <span>Startup</span>
                    </figure>
                    <div className="wrap_span">
                      <span className="sub_span">
                        <FaRegUserCircle />
                        Adam Smith
                      </span>
                      <span className="sub_span">
                        <FiCalendar />
                        Feb 28, 2023
                      </span>
                    </div>
                    <p>Benefits to first-generation low-income</p>
                    <Link to="#">
                      Read more <FaArrowRight />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="wrap_card">
                    <figure>
                      <img src={enterprenure} alt="" />
                      <span>Entrepreneurship</span>
                    </figure>
                    <div className="wrap_span">
                      <span className="sub_span">
                        <FaRegUserCircle />
                        Max Wevar
                      </span>
                      <span className="sub_span">
                        <FiCalendar />
                        Feb 10, 2023
                      </span>
                    </div>
                    <p>Dangers of a traditional for your career goal</p>
                    <Link to="#">
                      Read more <FaArrowRight />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="wrap_card">
                    <figure>
                      <img src={business} alt="" />
                      <span>Business</span>
                    </figure>
                    <div className="wrap_span">
                      <span className="sub_span">
                        <FaRegUserCircle />
                        Willium Jons
                      </span>
                      <span className="sub_span">
                        <FiCalendar />
                        Feb 03, 2023
                      </span>
                    </div>
                    <p>How to increase adreve ue in mobile applications</p>
                    <Link to="#">
                      Read more <FaArrowRight />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="wrap_card">
                    <figure>
                      <img src={update} alt="" />
                      <span>Startup</span>
                    </figure>
                    <div className="wrap_span">
                      <span className="sub_span">
                        <FaRegUserCircle />
                        Adam Smith
                      </span>
                      <span className="sub_span">
                        <FiCalendar />
                        Feb 28, 2023
                      </span>
                    </div>
                    <p>Benefits to first-generation low-income</p>
                    <Link to="#">
                      Read more <FaArrowRight />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="wrap_card">
                    <figure>
                      <img src={update} alt="" />
                      <span>Startup</span>
                    </figure>
                    <div className="wrap_span">
                      <span className="sub_span">
                        <FaRegUserCircle />
                        Max Wevar
                      </span>
                      <span className="sub_span">
                        <FiCalendar />
                        Feb 10, 2023
                      </span>
                    </div>
                    <p>Dangers of a traditional for your career goal</p>
                    <Link to="#">
                      Read more <FaArrowRight />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="wrap_card">
                    <figure>
                      <img src={update} alt="" />
                      <span>Startup</span>
                    </figure>
                    <div className="wrap_span">
                      <span className="sub_span">
                        <FaRegUserCircle />
                        Willium Jons
                      </span>
                      <span className="sub_span">
                        <FiCalendar />
                        Feb 03, 2023
                      </span>
                    </div>
                    <p>How to increase adreve ue in mobile applications</p>
                    <Link to="#">
                      Read more <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section> */}
      <section className="wrapper_contact">
        {/* <figure className="wrap_set_varrow">
          <img src={varrow} alt="" />
        </figure> */}
        <div className="contact_us">
          <div className="wrapper_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.7154410475896!2d77.4924632!3d28.457993200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc176005896c3%3A0x13e193d70f9c3c9d!2sNLIM!5e0!3m2!1sen!2sin!4v1714562260945!5m2!1sen!2sin"
              width="600"
              height="450"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="Custom_container">
            <div className="wrapper_form">
              <div className="headings">
                <h2 className="h2_subheading">Contact Us</h2>
              </div>
              <form>
                <div className="wrap_form_field">
                  <div className="form_field ">
                    <label className="label">Your First Name</label>
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="Enter your first name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                    {errors.firstName && (
                      <div className="error" style={{ color: "red" }}>
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className="form_field ">
                    <label className="label">Your Last Name</label>
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="Enter your last name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                    {errors.lastName && (
                      <div className="error" style={{ color: "red" }}>
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="wrap_form_field">
                  <div className="form_field">
                    <label className="label">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control input"
                      placeholder="Enter your mobile"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleInputChange}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                    />
                    {errors.mobileNumber && (
                      <div className="error" style={{ color: "red" }}>
                        {errors.mobileNumber}
                      </div>
                    )}
                  </div>
                  <div className="form_field">
                    <label className="label">Email address</label>
                    <input
                      type="email"
                      className="form-control input"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <div className="error" style={{ color: "red" }}>
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Subject</label>
                  <input
                    type="text"
                    className="form-control input"
                    placeholder="Enter subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                  />
                  {errors.subject && (
                    <div className="error" style={{ color: "red" }}>
                      {errors.subject}
                    </div>
                  )}
                </div>
                <div className="form_field">
                  <label className="label">Write your message</label>
                  <textarea
                    className="form-control input"
                    placeholder="Write us your question here..."
                    id="textarea"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                  {errors.message && (
                    <div className="error" style={{ color: "red" }}>
                      {errors.message}
                    </div>
                  )}
                </div>
                <div className="form_field text_right">
                  <button
                    ref={submitButtonElement}
                    className="nav_btn_cstm_more"
                    onClick={(e) => {
                      finalSubmitHandler(e);
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="wrapper_marque">
        <div className="Custom_container">
          <div className="marquee">
            <Marquee pauseOnHover>
              {arrays?.map((data) => (
                <div className="marque_image">
                  <figure>
                    <img src={ferns} alt="" />
                  </figure>
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </section> */}
      <CommonModal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        handleClose={() => setModalShow(false)}
        centered
      ></CommonModal>
    </>
  );
};

export default Landing;
