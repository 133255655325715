import React from "react";
import { about, leader, leaders, leaders3, whychoose } from "../DefaultImport";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="About_us_page">
      <section className="wrapper_about_heading">
        <div className="Custom_container">
          <div className="wrap_tittle">
            <h1 className="tittle_heading_h1">About Us</h1>
            <p className="tittle_sub_heading_p">
              A pioneering institute that redefines hospitality education for
              the modern world.
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper_about_section">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="txt_content">
                  <h2 className="h2_subheading">About Us</h2>
                  <p className="span_cnt">
                    "Welcome to NLIM, a pioneering institute that redefines
                    hospitality education for the modern world. With a steadfast
                    commitment to excellence and innovation, we prepare our
                    students not just for a job, but for a successful and
                    fulfilling career in the dynamic and ever-evolving
                    hospitality industry.
                  </p>
                  <p className="span_cnt">
                    <b>
                      At NLIM, we believe that hospitality is more than just a
                      service – it's an art,
                    </b>{" "}
                    a science, and a way of life. Our degree programs are
                    designed to cultivate the skills, knowledge, and mindset
                    needed to excel in this multifaceted field. Through a blend
                    of theoretical learning and hands-on experience, our
                    students develop a deep understanding of the industry and
                    the confidence to navigate its complexities.
                  </p>
                  <p className="span_cnt">
                    What sets us apart is our unwavering focus on practical
                    learning. Our curriculum, meticulously crafted by industry
                    experts, is tailored to meet the demands of the modern
                    hospitality landscape. From culinary arts to front office
                    management, our students learn from the best in the
                    business, gaining valuable insights and industry-relevant
                    skills that set them apart in the competitive job market.
                  </p>
                  {/* <button className="nav_btn_cstm_more">Read More</button> */}
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={whychoose} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_why_us">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={about} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="txt_content">
                  <h2 className="h3_subheading">
                    However, we are more than just an institute
                  </h2>
                  <p className="span_cnt">
                    we are a community. A community of passionate educators,
                    industry professionals, and aspiring hospitality leaders,
                    working together to shape the future of the hospitality
                    industry. Our state-of-the-art facilities, industry
                    partnerships, and global network ensure that our students
                    have access to the latest trends and developments in the
                    field.
                  </p>
                  <p className="span_cnt">
                    Join us at NLIM and embark on a transformative journey
                    towards a rewarding career in hospitality. Discover your
                    passion, unleash your creativity, and build the skills you
                    need to succeed in the exciting world of hospitality.
                    Welcome to the future of hospitality education. Welcome to
                    NLIM."
                  </p>
                  <Link to="/contactus" className="nav_btn_cstm_more">
                    Contact Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper_leadership">
        <div className="Custom_container">
          <div className="programe_content">
            <div className="headings">
              <h2 className="h2_subheading">Our Leadership</h2>
              <p className="span_cnt">
                Be assured of the right guidance and expert leadership as our
                mentors come with years of industry experience and brilliant
                knowledge.
              </p>
            </div>
            <div className="wrap_leader_image">
              <div className="wrap_card">
                <figure>
                  <img src={leader} alt="" />
                  <div className="image_layer">
                    <p>Dr. SANGITA SHARMA</p>
                    <span>MD and CEO</span>
                  </div>
                </figure>
              </div>
              <div className="wrap_card">
                <figure>
                  <img src={leaders} alt="" />
                  <div className="image_layer">
                    <p>Amitabh Sahay</p>
                    <span>Director Training and Placement</span>
                  </div>
                </figure>
              </div>
              <div className="wrap_card">
                <figure>
                  <img src={leaders3} alt="" />
                  <div className="image_layer">
                    <p>SHAURYA SHARMA</p>
                    <span>Director PR and Legal </span>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
