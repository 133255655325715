import { useRef, useState, useEffect } from "react";

const useButtonLoader = (defaultText = "Load", loadingText = "Loading...") => {
  const [isLoading, setLoading] = useState(false);
  const element = useRef(null);

  useEffect(() => {
    if (isLoading) {
      element.current.disabled = true;
      element.current.innerHTML =
        '<i class="fa fa-spinner fa-spin"></i> ' + loadingText;
    } else {
      //console.log(element.current);
      if (element.current) {
        element.current.disabled = false;
        element.current.innerHTML = defaultText;
      }
    }
  }, [isLoading]);

  return [element, setLoading];
};

export default useButtonLoader;
