import { HashRouter, BrowserRouter } from "react-router-dom";
// import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Routing from "./routing/Routing";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import ScrollToTop from "./routing/ScrollTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routing />
      </BrowserRouter>
    </div>
  );
}

export default App;
