import React from "react";
import { about, diploma2, programe1 } from "../DefaultImport";
import { Link } from "react-router-dom";
import CommonModal from "../components/modal/Modal";
import { DownloadBrochure } from "../DefaultImportComponent";

const AdvanceDiploma = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="Advance_Diploma_page">
      <section className="wrapper_about_heading">
        <div className="Custom_container">
          <div className="wrap_tittle">
            <h1 className="tittle_heading_h1">Advanced Diploma</h1>
            <p className="tittle_sub_heading_p">2-year diploma course</p>
          </div>
        </div>
      </section>
      <section className="wrapper_about_section">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-7 col-md-5 col-sm-12">
                <div className="txt_content">
                  <h2 className="h2_subheading">
                    Advanced Diploma in Hotel Management
                  </h2>
                  <p className="span_cnt">
                    Take your hospitality career to new heights with our
                    Advanced Diploma in Hotel Management. This intensive 2-year
                    program delves deeper into key areas of hotel management,
                    including revenue management, strategic planning, and
                    customer relationship management.
                  </p>
                  <p className="span_cnt">
                    <b>
                      You will have the opportunity to further hone your skills
                      through a 6-month internship
                    </b>{" "}
                    at a prestigious hotel or resort, gaining valuable hands-on
                    experience and industry insights. With a focus on leadership
                    development and practical training, this advanced diploma
                    will prepare you for managerial roles in the hospitality
                    industry.
                  </p>
                  <button
                    onClick={() => setModalShow(true)}
                    className="nav_btn_cstm_more"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={diploma2} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DownloadBrochure />
      <CommonModal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        handleClose={() => setModalShow(false)}
        centered
      ></CommonModal>
    </div>
  );
};

export default AdvanceDiploma;
