import React from "react";
import { Link } from "react-router-dom";
import {
  galleryimg1,
  galleryimg2,
  galleryimg3,
  galleryimg4,
  galleryimg5,
  galleryimg6,
  galleryimg7,
} from "../DefaultImport";
import { DownloadBrochure } from "../DefaultImportComponent";

const Gallery = () => {
  return (
    <div className="Gallery_page">
      <section className="wrapper_about_heading">
        <div className="Custom_container">
          <div className="wrap_tittle">
            <h1 className="tittle_heading_h1">Gallery</h1>
            <p className="tittle_sub_heading_p">
              Take a look into our world full of amazing experiences &
              achievements
            </p>
          </div>
        </div>
      </section>
      <section className="wrapper_gallery">
        <div className="Custom_container">
          <div className="wrap_gallery_img">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap-img_card">
                  <figure>
                    <img src={galleryimg1} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap-img_card">
                  <figure>
                    <img src={galleryimg2} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap-img_card">
                  <figure>
                    <img src={galleryimg3} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap-img_card">
                  <figure>
                    <img src={galleryimg4} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap-img_card">
                  <figure>
                    <img src={galleryimg5} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap-img_card">
                  <figure>
                    <img src={galleryimg6} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap-img_card">
                  <figure>
                    <img src={galleryimg7} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DownloadBrochure />
    </div>
  );
};

export default Gallery;
