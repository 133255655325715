import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import useButtonLoader from "../hooks/useButtonLoader";

const ContactUs = () => {
  const form = useRef();
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Send Message",
    "Please wait..."
  );
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null,
    });
  };
  const errHandler = () => {
    // Validate form fields
    let formIsValidate = true;
    const validationErrors = {};
    if (!formData.firstName.trim()) {
      validationErrors.firstName = "First Name is required";
      formIsValidate = false;
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = "Last Name is required";
      formIsValidate = false;
    }
    if (!formData.mobileNumber.trim()) {
      validationErrors.mobileNumber = "Mobile Number is required";
      formIsValidate = false;
    } else if (
      isNaN(formData.mobileNumber) ||
      formData.mobileNumber.length !== 10
    ) {
      validationErrors.mobileNumber =
        "Mobile Number should be a valid 10-digit number";
      formIsValidate = false;
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
      formIsValidate = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is invalid";
      formIsValidate = false;
    }
    if (!formData.subject.trim()) {
      validationErrors.subject = "Subject is required";
      formIsValidate = false;
    }
    if (!formData.message.trim()) {
      validationErrors.message = "Message is required";
      formIsValidate = false;
    }
    setErrors(validationErrors);
    return formIsValidate;
  };
  const finalSubmitHandler = (e) => {
    e.preventDefault();
    console.log(errHandler(), "handler:'");

    if (errHandler()) {
      console.log("emailParams++++");
      const templateParams = {
        mobile_number: formData.mobileNumber,
        email_address: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        subject: formData.subject,
        message: formData.message,
      };
      console.log(templateParams, "emailParams");
      setSubmitLoading(true);
      emailjs
        .send("service_le5ewcd", "template_n6kietg", templateParams, {
          publicKey: "Hqybjk1JKXCYcNn29",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            toast.success("Your message has been sent successfully!");
            setSubmitLoading(false);
            setFormData({
              firstName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
              subject: "",
              message: "",
            });
          },
          (error) => {
            console.log("FAILED...", error);
            toast.error("Failed to send your message. Please try again.");
          }
        );
    } else {
      errHandler();
    }
  };
  return (
    <div className="Contact_us_page">
      <section className="wrapper_about_heading">
        <div className="Custom_container">
          <div className="wrap_tittle">
            <h1 className="tittle_heading_h1">Contact Us</h1>
            <p className="tittle_sub_heading_p">
              Fill in your basic details. Our team will contact you without
              delay.
            </p>
          </div>
        </div>
      </section>
      <section className="contact_page_form">
        <div className="Custom_container">
          <div className="wrapper_form">
            <form>
              <div className="wrap_form_field">
                <div className="form_field">
                  <label className="label">Your First Name</label>
                  <input
                    type="text"
                    className="form-control input"
                    placeholder="Enter your first name"
                    name="firstName"
                    valaue={formData.firstName}
                    onChange={handleInputChange}
                  />
                  {errors.firstName && (
                    <div className="error" style={{ color: "red" }}>
                      {errors.firstName}
                    </div>
                  )}
                </div>
                <div className="form_field">
                  <label className="label">Your Last Name</label>
                  <input
                    type="text"
                    className="form-control input"
                    placeholder="Enter your last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                  {errors.lastName && (
                    <div className="error" style={{ color: "red" }}>
                      {errors.lastName}
                    </div>
                  )}
                </div>
              </div>
              <div className="wrap_form_field">
                <div className="form_field">
                  <label className="label">Mobile Number</label>
                  <input
                    type="number"
                    className="form-control input"
                    placeholder="Enter your mobile"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                  />
                  {errors.mobileNumber && (
                    <div className="error" style={{ color: "red" }}>
                      {errors.mobileNumber}
                    </div>
                  )}
                </div>
                <div className="form_field">
                  <label className="label">Email address</label>
                  <input
                    type="email"
                    className="form-control input"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <div className="error" style={{ color: "red" }}>
                      {errors.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="form_field">
                <label className="label">Subject</label>
                <input
                  type="text"
                  className="form-control input"
                  placeholder="Enter subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                />
                {errors.subject && (
                  <div className="error" style={{ color: "red" }}>
                    {errors.subject}
                  </div>
                )}
              </div>
              <div className="form_field">
                <label className="label">Write your message</label>
                <textarea
                  className="form-control input"
                  placeholder="Write us your question here..."
                  id="textarea"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
                {errors.message && (
                  <div className="error" style={{ color: "red" }}>
                    {errors.message}
                  </div>
                )}
              </div>
              <div className="form_field text_right">
                <button
                  ref={submitButtonElement}
                  className="nav_btn_cstm_more"
                  onClick={(e) => {
                    finalSubmitHandler(e);
                  }}
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="wrapper_map">
        <div className="map_view">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.7154410475896!2d77.4924632!3d28.457993200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc176005896c3%3A0x13e193d70f9c3c9d!2sNLIM!5e0!3m2!1sen!2sin!4v1714562260945!5m2!1sen!2sin"
            width="600"
            height="450"
            // style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </div>
  );
};
export default ContactUs;
