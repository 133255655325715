import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import {
  AboutUs,
  // Admission,
  AdvanceDiploma,
  BachelorStudies,
  ContactUs,
  Diploma,
  Footer,
  Gallery,
  Header,
  Landing,
  OurPartner,
} from "../DefaultImportComponent";

const Routing = () => {
  const CustomRoutes = () => {
    return (
      <>
        <Header />
        <div className="wrapper_landing">
          <div className="dashbaord_content">
            <Outlet />
          </div>
        </div>
        <Footer />
      </>
    );
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<CustomRoutes />}>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/diploma" element={<Diploma />} />
          <Route path="/advancediploma" element={<AdvanceDiploma />} />
          <Route path="/bachelorstudies" element={<BachelorStudies />} />
          <Route path="/ourpartner" element={<OurPartner />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="/admission" element={<Admission />} /> */}
          <Route path="/contactus" element={<ContactUs />} />
        </Route>
      </Routes>
    </>
  );
};
export default Routing;
