import React from "react";
import { about, diploma1, programe } from "../DefaultImport";
import { Link } from "react-router-dom";
import CommonModal from "../components/modal/Modal";
import { DownloadBrochure } from "../DefaultImportComponent";

const Diploma = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="Diploma_page">
      <section className="wrapper_about_heading">
        <div className="Custom_container">
          <div className="wrap_tittle">
            <h1 className="tittle_heading_h1">Diploma in Hotel Management</h1>
            <p className="tittle_sub_heading_p">1-year diploma course</p>
          </div>
        </div>
      </section>
      <section className="wrapper_about_section">
        <div className="Custom_container">
          <div className="about_content">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="txt_content">
                  <h2 className="h2_subheading">Diploma in Hotel Management</h2>
                  <p className="span_cnt">
                    Our Diploma in Hotel Management is a transformative 1-year
                    program designed to kickstart your career in the hospitality
                    industry. Through a blend of classroom lectures, practical
                    workshops, and industry visits, you will gain a
                    comprehensive understanding of hotel operations, guest
                    services, and food and beverage management.
                  </p>
                  <p className="span_cnt">
                    <b>
                      You will also have the opportunity to participate in a
                      3-month internship
                    </b>{" "}
                    at a leading hotel or resort,where you can apply your skills
                    in a real-world setting. By the end of the program, you will
                    be equipped with the knowledge and experience needed to
                    excel in entry-level positions in the hospitality industry.
                  </p>

                  <button
                    onClick={() => setModalShow(true)}
                    className="nav_btn_cstm_more"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="img_content">
                  <figure>
                    <img src={diploma1} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DownloadBrochure />
      <CommonModal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        handleClose={() => setModalShow(false)}
        centered
      ></CommonModal>
    </div>
  );
};

export default Diploma;
